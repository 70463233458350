import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { FaChartLine, FaRobot, FaDatabase, FaComments, FaChartBar, FaArrowRight, FaFileAlt } from 'react-icons/fa';

const HomePage = () => {
  const [activeProduct, setActiveProduct] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const products = [
    { name: "Mr. Graham", description: "AI-powered fundamental analysis of 5000+ listed companies" },
    { name: "Coming Soon" },
  ];

  const features = [
    { icon: <FaChartLine />, title: "Real-Time Analysis", description: "Live data on 5000+ listed companies" },
    { icon: <FaRobot />, title: "AI-Powered Insights", description: "Advanced algorithms for deep financial analysis" },
    { icon: <FaDatabase />, title: "Comprehensive Data", description: "100+ financial metrics per company" },
    { icon: <FaComments />, title: "Natural Language Queries", description: "Ask complex financial questions in plain English" },
    { icon: <FaChartBar />, title: "Visual Analytics", description: "Dynamic charts for key financial metrics" },
    { icon: <FaFileAlt />, title: "Custom Reports", description: "Tailored financial reports and summaries" },
  ];

  const FinanceAnimation = () => (
    <motion.div className="w-full h-0 relative mb-0">
      <motion.div
        className="absolute w-full h-full"
        animate={{
          rotate: 360,
        }}
        transition={{
          duration: 20,
          ease: "linear",
          repeat: Infinity,
        }}
      >
        {[...Array(8)].map((_, index) => (
          <motion.div
            key={index}
            className="absolute w-2 h-2 bg-green-500 rounded-full"
            style={{
              top: `${50 + 40 * Math.sin(index * Math.PI / 4)}%`,
              left: `${50 + 40 * Math.cos(index * Math.PI / 4)}%`,
            }}
            animate={{
              scale: [1, 1.5, 1],
              opacity: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              delay: index * 0.25,
            }}
          />
        ))}
      </motion.div>
      {['$', '%', '📈', '📉'].map((symbol, index) => (
        <motion.div
          key={symbol}
          className="absolute text-2xl"
          initial={{ opacity: 0, scale: 0 }}
          animate={{
            opacity: [0, 1, 0],
            scale: [0.5, 1.5, 0.5],
            x: ['-50%', '50%', '-50%'],
            y: ['-50%', '50%', '-50%'],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            delay: index,
          }}
          style={{
            top: '50%',
            left: '50%',
          }}
        >
          {symbol}
        </motion.div>
      ))}
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-100 text-gray-900 overflow-hidden font-sans">
      <Helmet>
        <title>Home - Graham AI</title>
      </Helmet>
      <header className="fixed top-0 left-0 right-0 z-50 py-6 px-4 sm:px-6 lg:px-8 bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg shadow-md">
        <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center">
          <motion.h1
            className="text-2xl sm:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-600 mb-4 sm:mb-0"
            whileHover={{ scale: 1.05 }}
          >
            Graham AI
          </motion.h1>
          <nav>
            <ul className="flex space-x-4 sm:space-x-6">
              <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                <a href="/mrgraham" className="hover:text-green-600 transition duration-300 text-sm font-medium">Try Mr. Graham</a>
              </motion.li>
            </ul>
          </nav>
        </div>
      </header>

      <main className="max-w-7xl mx-auto py-20 pt-32 sm:pt-48 px-4 sm:px-6 lg:px-8">
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          className="text-center mb-16 sm:mb-24 relative"
        >
          <motion.div
            className="absolute top-0 left-0 w-full h-full"
            style={{
              background: `radial-gradient(circle, rgba(72,187,120,0.1) 0%, rgba(255,255,255,0) 70%)`,
              filter: 'blur(100px)',
            }}
            animate={{
              scale: [1, 1.2, 1],
            }}
            transition={{
              duration: 20,
              ease: "linear",
              repeat: Infinity,
            }}
          />
          <h2 className="text-4xl sm:text-5xl md:text-6xl mt-8 font-black mb-6 relative z-10">
            <motion.span
              className="inline-block"
              animate={{
                y: [0, -5, 0],
              }}
              transition={{
                duration: 5,
                ease: "easeInOut",
                repeat: Infinity,
              }}
            >
              AI-Powered
            </motion.span>{' '}
            <motion.span
              className="inline-block text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-600"
              animate={{
                y: [0, -5, 0],
              }}
              transition={{
                duration: 5,
                ease: "easeInOut",
                repeat: Infinity,
                delay: 0.5,
              }}
            >
              Finance
            </motion.span>
          </h2>
          <p className="text-lg sm:text-xl mb-0 text-gray-700 max-w-2xl mx-auto font-light">
            The Future of Finance
          </p>
          <FinanceAnimation />
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.2 }}
          className="mb-16 sm:mb-24"
        >
          <h3 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-8 sm:mb-12 text-center">Our AI-Powered Solutions</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-8">
            {products.map((product, index) => (
              <motion.div
                key={product.name}
                className={`bg-gradient-to-br ${
                  index === activeProduct ? 'from-green-200 to-blue-200' : 'from-gray-100 to-white'
                } rounded-2xl p-6 sm:p-8 cursor-pointer transition-all duration-300 shadow-2xl`}
                whileHover={{ scale: 1.03, boxShadow: "0px 0px 30px rgba(72,187,120,0.3)" }}
                onClick={() => setActiveProduct(index)}
              >
                <h4 className="text-2xl sm:text-3xl font-semibold mb-4">{product.name}</h4>
                <p className="text-gray-700 text-base sm:text-lg mb-6">{product.description}</p>
                {index === activeProduct && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    <a href="/mrgraham" className="text-green-600 flex items-center text-lg font-medium">
                      Try Mr.Graham<FaArrowRight className="ml-2" />
                    </a>
                  </motion.div>
                )}
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.4 }}
          className="mb-16 sm:mb-24"
        >
          <h3 className="text-2xl sm:text-3xl md:text-4xl ml-2 font-bold mb-8 sm:mb-12 text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-600">Introducing Mr. Graham: Your Equity Analyst</h3>
          <div className="bg-gradient-to-r from-white to-gray-100 rounded-3xl p-6 sm:p-8 md:p-12 shadow-xl relative overflow-hidden">
            <motion.div 
              className="absolute top-0 right-0 w-64 h-64 sm:w-96 sm:h-96 bg-blue-400 rounded-full filter blur-2xl opacity-30"
              animate={{ 
                scale: [1, 1.2, 1],
                rotate: [0, 45, 0]
              }}
              transition={{
                duration: 10,
                ease: "easeInOut",
                repeat: Infinity,
              }}
            />
            <div className="relative z-10">
              <h4 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-gray-900">Mr. Graham: Advanced Fundamental Analysis</h4>
              <p className="text-base sm:text-lg mb-6 sm:mb-8 leading-relaxed text-gray-800">
                Mr. Graham, powered by Graham AI, combines artificial intelligence with fundamental analysis. With real-time data on over 5000 listed companies and more than 100 financial metrics per company, it provides unparalleled depth and breadth in financial analysis.
              </p>
              <ul className="list-disc list-inside mb-8 sm:mb-10 text-gray-700 grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 text-sm sm:text-base">
                <li>Real-time analysis of 5000+ listed companies</li>
                <li>100+ financial metrics tracked per company</li>
                <li>Advanced ratio analysis and trend identification</li>
                <li>Natural language processing for complex queries</li>
                <li>Customizable reports and data visualization</li>
                <li>Historical data analysis</li>
              </ul>
              <p className="text-sm text-gray-600 mb-6">
                Note: Mr. Graham provides in-depth analysis and insights but does not offer personalized investment advice. Always consult with a qualified financial advisor before making investment decisions.
              </p>
              <motion.a
                href="/mrgraham"
                whileHover={{ scale: 1.05, boxShadow: "0px 0px 20px rgba(59,130,246,0.5)" }}
                whileTap={{ scale: 0.95 }}
                className="inline-block bg-gradient-to-r from-blue-500 to-indigo-500 text-white font-bold py-3 px-6 sm:px-8 rounded-full text-sm sm:text-base shadow-lg hover:shadow-xl transition duration-300 uppercase tracking-wider"
              >
                Experience Mr. Graham
              </motion.a>
            </div>
          </div>
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.4 }}
          className="mb-16 sm:mb-24"
        >
          <h3 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-8 sm:mb-12 text-center">Features</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                className="bg-white rounded-2xl p-6 sm:p-8 shadow-xl transition-transform transform hover:scale-105"
              >
                <div className="text-green-600 mb-4 text-4xl">{feature.icon}</div>
                <h4 className="text-xl sm:text-2xl font-semibold mb-4">{feature.title}</h4>
                <p className="text-gray-600 text-base sm:text-lg">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>
      </main>

      <div className="relative">
        <img 
          src={`${process.env.PUBLIC_URL}/data.png`} 
          alt="Data Visualization" 
          className="w-full h-auto object-cover mt-12"
        />
      </div>

      <footer className="py-6 px-4 sm:px-6 lg:px-8 bg-white shadow-inner text-center">
        <p className="text-gray-700 text-sm">&copy; 2024 Graham AI. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HomePage;
