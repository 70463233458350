import React, { useState, useEffect, useRef } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const recognizeIntent = (query) => {
  const lowerCaseQuery = query.toLowerCase();
  if (lowerCaseQuery.includes('performance') || lowerCaseQuery.includes('financials') || lowerCaseQuery.includes('market cap') || lowerCaseQuery.includes('pe ratio') || lowerCaseQuery.includes('roe')) {
    return 'performance';
  }
  return 'general';
};

function MrGrahamApp() {
  const [sessionId, setSessionId] = useState(localStorage.getItem('sessionId') || Date.now().toString());
  const [chatHistory, setChatHistory] = useState(JSON.parse(localStorage.getItem('chatHistory')) || []);
  const [userInput, setUserInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const lastMessageRef = useRef(null);

  useEffect(() => {
    localStorage.setItem('sessionId', sessionId);
    localStorage.setItem('chatHistory', JSON.stringify(chatHistory));
  }, [sessionId, chatHistory]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  useEffect(() => {
    const cleanupInterval = setInterval(() => {
      axios.post('https://glacial-wildwood-18418-0f87b0df699e.herokuapp.com/api/cleanup', { session_id: sessionId })
        .catch(error => console.error('Error during cleanup:', error));
    }, 300000);

    return () => clearInterval(cleanupInterval);
  }, [sessionId]);

  const addMessage = (sender, message) => {
    const timestamp = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    const formattedMessage = { sender, message, timestamp };
    setChatHistory(prevHistory => [...prevHistory, formattedMessage]);
  };

  const handleSendMessage = async () => {
    // Temporarily disabled
  };

  const handleNewAnalysis = () => {
    // Temporarily disabled
  };

  return (
    <div className="flex flex-col h-screen bg-gray-50 text-gray-900">
      <Helmet>
        <title>Mr. Graham - Your Equity Analyst</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <div className="flex-grow flex flex-col p-4 sm:p-6 overflow-hidden">
        <h1 className="text-3xl sm:text-4xl md:text-5xl text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-600 font-extrabold text-center mb-4 sm:mb-6">
          Mr. Graham - Your Equity Analyst
        </h1>
        <button
          onClick={handleNewAnalysis}
          className="mb-4 sm:mb-6 py-2 px-4 text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition duration-200 self-center sm:self-end opacity-50 cursor-not-allowed"
          disabled
        >
          Start New Analysis
        </button>

        <div className="flex-grow overflow-y-auto p-4 sm:p-6 rounded-lg border border-gray-900" ref={chatContainerRef}>
          <div className="flex items-center justify-center h-full">
            <p className="text-center text-2xl font-bold text-blue-600">
              Coming Soon!<br />
              <span className="text-lg font-normal text-gray-600">
                We're currently undergoing maintenance to improve ourselves.<br />
                Please check back later.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="p-4 sm:p-6 bg-gray-100 border-t border-gray-300">
        <div className="flex items-center">
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="coming soon..."
            className="flex-grow p-3 text-sm sm:text-base rounded-l-lg bg-white text-gray-400 border border-gray-300 focus:outline-none cursor-not-allowed"
            disabled
          />
          <button
            className="bg-gradient-to-r from-blue-500 to-blue-700 text-white px-4 sm:px-5 py-3 text-sm sm:text-base rounded-r-lg transition-transform transform hover:scale-105 flex items-center justify-center gap-2 opacity-50 cursor-not-allowed"
            disabled
          >
            <FaPaperPlane className="text-sm sm:text-base" /> 
            <span className="hidden sm:inline">Submit</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default MrGrahamApp;